import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate } from 'react-router-dom';
import { usePayByLinkStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import PayByLinkHeader from '../../../../components/PayByLinkHeader/PayByLinkHeader.component';
import { ResponsiveContainer } from '../../../../style/Container.style';
import styled, { css, StyleSheetManager } from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { IDs } from '../../../../utils/config/ids';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton.component';
import { formConfigEditPlanByShippingDate } from './EditPlanByShippingDate.helpers';
import DatePickerField from '../../../../components/DatePickerField/DatePickerField.component';
import moment from 'moment/moment';
import media from '../../../../style-utils/media-queries';
import StepperRemodulationByDate from '../../../../components/Stepper/StepperRemodulationByDate.component';
import { Card } from 'src/style/Container.style';
import { shippingDateField } from '../../../../services/orderLink/orderLink.config';
import { RoutePath } from '../../../../routers/routers.config';
import { shouldForwardProp } from 'src/style-utils/functions';

const FlowContainerStyled = styled(ResponsiveContainer)`
  h4 {
    margin-bottom: 1.6rem;
  }

  ${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : css`
          margin-top: 4.8rem;
        `}

  ${media.mobileSm`
   margin-bottom: 13.8rem;
  `}

    .react-datepicker-wrapper {
    width: 100%;
  }
`;

function EditPlanByShippingDate() {
  const navigate = useNavigate();
  const { installments, editStartINo, shippingDate, amount } =
    usePayByLinkStore((state) => state);
  const { resolver } = formConfigEditPlanByShippingDate();
  const methods = useForm({
    defaultValues: {
      [shippingDateField]: shippingDate
        ? new Date(moment(shippingDate).toString())
        : null,
    },
    resolver,
    mode: 'onChange',
  });
  const watchedValues = methods.watch();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const minDate = moment().startOf('day');
  const maxDate = moment().startOf('day').add(1, 'year').subtract(1, 'day');
  const onSubmit = () => {
    const shippingDateUTC = watchedValues[shippingDateField]
      ? moment
          .utc(
            moment(watchedValues[shippingDateField])
              .startOf('day') // Set to the start of the day in local time
              .format('YYYY-MM-DD'), // Format as YYYY-MM-DD to ignore time
          )
          .toISOString() // Convert to ISO string in UTC
      : null;

    setValue('shippingDate', shippingDateUTC);

    const installmentUnpaid = installments?.filter(
      (item) => editStartINo && item.Number >= editStartINo,
    );

    const { installmentMinAmount, installmentMaxAmount } =
      installmentUnpaid?.reduce(
        (
          acc: { installmentMinAmount: number; installmentMaxAmount: number },
          installment,
        ) => {
          return {
            installmentMinAmount: Math.min(
              acc.installmentMinAmount,
              installment.Amount,
            ),
            installmentMaxAmount: Math.max(
              acc.installmentMaxAmount,
              installment.Amount,
            ),
          };
        },
        {
          installmentMinAmount: Infinity,
          installmentMaxAmount: -Infinity,
        },
      ) || { installmentMinAmount: 0, installmentMaxAmount: 0 };

    setValue('customInstallments', {
      NumRate: installmentUnpaid?.length || 0,
      installmentMinAmount,
      installmentMaxAmount,
      InstallmentAmount: amount,
      Installments: installmentUnpaid?.map((item, i) => ({
        ...item,
        Expiration: watchedValues[shippingDateField]
          ? moment(watchedValues[shippingDateField])
              .add(30 * (i + 1), 'day')
              .toISOString()
          : null,
      })),
    });

    navigate(`${RoutePath.createLink}FLX/RML/${RoutePath.stepResume}`);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={1}
        stepMax={2}
        title="text.step.shipping.date"
      />
      <FlowContainerStyled>
        <div className="mb-28 grid lg:mb-0 lg:grid-cols-2 lg:gap-28">
          <div>
            <H4 light>
              <Translate id="text.new.date.shipping" />
            </H4>
            <P className="mb-8 mt-8">
              <Translate id="text.new.date.shipping.sub" />
            </P>
            <div>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <DatePickerField
                    className={'w-full'}
                    name={shippingDateField}
                    minDate={new Date(minDate.toString())}
                    maxDate={new Date(maxDate.toString())}
                    popperModifiers={[
                      {
                        name: 'arrow',
                        options: { padding: 24 },
                      },
                    ]}
                    isPresentMessage
                  />
                  <ButtonContainer>
                    <SubmitButton
                      id={IDs.btnProceed}
                      minWidth="100%"
                      disabled={!methods.getValues()['shippingDate']}
                    >
                      <Translate id="lbl.next" />
                    </SubmitButton>
                  </ButtonContainer>
                </form>
              </FormProvider>
            </div>
          </div>
          <div>
            <Card maxWidth="100%" padding="1.6rem">
              <span className="text-sm lg:text-base">
                <Translate id="text.rataPlanning" />
              </span>
              <StepperRemodulationByDate
                currentDate={watchedValues[shippingDateField]}
                numInstallmentToShow={editStartINo}
                dataStepper={installments as any}
                onlyToPay
              />
            </Card>
          </div>
        </div>
      </FlowContainerStyled>
    </StyleSheetManager>
  );
}

export default EditPlanByShippingDate;
