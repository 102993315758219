import Loader from 'src/components/Loader/Loader.component';
import moment from 'moment';
import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useLanguage } from 'src/services/i18n/i18n.context';
import routerConfig from 'src/routers/routers.container';
import './App.css';
import IndigoWidget from '../IndigoWidget/IndigoWidget.component';
import useCookieHub from '../../utils/hooks/cookiehub';
import { clarity } from 'react-microsoft-clarity';
import { ENV } from 'src/settings';

const router = createBrowserRouter(routerConfig);

const App: React.FC = () => {
  useCookieHub();
  const { language, isLoadingLang, isLoadingUpLang, isLangLabelsLoaded } =
    useLanguage();
  useEffect(() => {
    moment.locale(language.substring(0, 2));
  }, [language]);

  switch (ENV) {
    case 'dev':
      clarity.init('qfx40pdgg9');
      break;
    case 'test':
      clarity.init('qfx4ii6eii');
      break;
    case 'production':
      clarity.init('qbayttrcqo');
      break;
  }

  return (
    <>
      <IndigoWidget />
      {isLoadingLang || isLoadingUpLang || !isLangLabelsLoaded ? (
        <Loader overlayViewMode="fullscreen" active={true} viewMode="fluid" />
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
};

export default App;
